 
import './App.css'; 
import Header from './components/Header';
import TopCard from './components/TopCard';
import Index from './components/charts';
import Card1 from './components/cards/card1';
 

function App() {
  return (
    <>
     <Header/> 
     <TopCard/>
     <Card1/>
     <Index/> 
    </>
   
  );
}

export default App;
